import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const AppointmentsPage = () => (
  <Layout>
    <Seo
      title="Termine und Preise"
      description="Melden Sie sich per E-Mail oder Telefon zur Beratung an. Hier finden Sie meine Kontaktdaten und Preise."
      keywords={['Termine', 'Preise', 'Honorar', 'Kontakt', 'Beratung']}
    />
    <div className="fl w-100 w-50-ns pa2 pt0">
      <StaticImage
        src={'../images/zimmer.jpg'}
        alt="Beratungszimmer"
        imgClassName="mb2 mr2"
      />
    </div>
    <article>
      <h1>Termine und Preise</h1>
      <p>
        Die Sitzungen dauern 60, 75 oder 90 Minuten. Die Anmeldung zu einem
        Kennenlern- und Sondierungsgespräch erfolgt über E-Mail oder Telefon.{' '}
      </p>
      <p>
        Stefan Schopf
        <br />
        Kaulbachstr. 14
        <br />
        80539 München
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://goo.gl/maps/AYVyShuwbTG2"
        >
          &#x2197; Auf Google Maps ansehen
        </a>
      </p>
      <p>
        Telefon <a href="tel:+498966656490">(089) 666 56 490</a>
        <br />
        E-Mail:{' '}
        <a href="mailto:stefan.schopf@posteo.de">stefan.schopf@posteo.de</a>
      </p>
      <p className="b">Einzelpersonen</p>
      <p>
        60 min | 76 €<br />
        75 - 90 min | 95 €
      </p>
      <p className="b">Paarberatung, Familienberatung</p>
      <p>
        75 min | 95 €<br />
        90 min | 114 €
      </p>
      <p className="b">Coaching</p>
      <p>Nach Vereinbarung</p>
    </article>
  </Layout>
)

export default AppointmentsPage
